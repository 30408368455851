import Vue from 'vue'
import { mapState } from 'vuex'
import Component from 'vue-class-component'
import Layout from '@/apps/core/components/Layout'
import { BASE_URL, RESPONSE_LEVEL } from '@/variables'
import sections from '../../views/defaultNavigationDrawerSections'
import CustomLayout from '@/apps/core/components/CustomLayout'
import NoteLinesTable from '@/apps/dido/components/tables/NoteLinesTable/NoteLinesTable.vue'
import { createNotification } from '@/lib/unnotificationsqueue'
import DateRangePickerInField from '@/lib/uncrudtable/components/forms/DateRangePickerInField'

@Component({
  components: {
    Layout,
    CustomLayout
  },
  props: {},
  computed: {
    ...mapState('notes', ['currentItem'])
  }
})

class NotesDetailView extends Vue {
  storeModule = 'notes'
  title = null
  formTitle = null
  noteId = null
  showForm = false
  customForm = true
  loading = false
  sections = sections
  options = {
    context: {
      noteId: this.getNoteId,
      serverUrl: BASE_URL
    }
  }

  customComponentCatalog = {
    'NoteLinesTable': NoteLinesTable,
    'DateRangePickerInField': DateRangePickerInField
  }

  customActions = []
  actionSelected = {}

  getNoteId () {
    return this.noteId || -1
  }

  async created () {
    this.noteId = this.$route.params.id
    this.title = this.$t('notes.noteDetailSection')
    await this.$store.dispatch(`${this.storeModule}/getNote`, this.noteId)
    this.showForm = true
    this.formTitle = this.currentItem.name
    this.customActions = this.insertCustomActionsValues()
  }

  async saveForm (config = {}) {
    this.loading = true
    try {
      let noteResponse = await this.$store.dispatch(`${this.storeModule}/updateNote`, this.noteId)
      if (config.redirect) {
        this.$router.push({ name: 'NotesList' })
      } else {
        await this.$store.dispatch('addNotification', createNotification(noteResponse.message, noteResponse.level))
      }
    } catch (error) {
      await this.$store.dispatch('addNotification', createNotification(error.message, error.level))
    } finally {
      this.loading = false
    }
  }

  async deleteForm () {
    this.loading = true
    try {
      let response = await this.$store.dispatch(`${this.storeModule}/deleteNote`, this.noteId)
      if (response.level === RESPONSE_LEVEL.SUCCESS) {
        await this.$router.push({ name: 'NotesList' })
        this.$store.dispatch('addNotification', createNotification(response.message, response.level))
      } else {
        this.$store.dispatch('addNotification', createNotification(response.message, response.level))
      }
    } finally {
      this.loading = false
    }
  }

  updateItem (items) {
    this.$store.dispatch(`${this.storeModule}/updateCurrentItem`, { ...this.currentItem, ...items })
  }

  insertCustomActionsValues () {
    return [
      {
        label: this.$t('general.delete'),
        method: this.deleteForm,
        confirm: true,
        confirmOptions: {
          titleDialog: this.$t('notes.deleteMessage'),
          confirmText: this.$t('dialogs.confirmOption'),
          confirmColor: 'red'
        },
        icon: 'fa-times',
        color: 'red',
        dark: true,
        floatEnd: true
      },
      {
        label: this.$t('customActions.saveAndContinue'),
        method: this.saveForm,
        args: { redirect: false },
        validate: true,
        icon: 'fa-save',
        color: 'primary'
      },
      {
        label: this.$t('general.save'),
        method: this.saveForm,
        args: { redirect: true },
        validate: true,
        icon: 'fa-save',
        color: 'primary'
      }
    ]
  }
}

export default NotesDetailView
