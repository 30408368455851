import { filtersMixin } from '@/lib/uncrudtable/mixins/filters'
import { validationMixin } from '@/apps/core/mixins/validations'
import { createNotification } from '@/lib/unnotificationsqueue'
import apiClient from '@/lib/unlogin/store/apiclient'
import DynamicSelectorTable from '@/apps/titan/components/tables/DynamicSelectorTable/DynamicSelectorTable.vue'

export default {
  name: 'NoteLineForm',
  components: { DynamicSelectorTable },
  mixins: [ validationMixin, filtersMixin ],
  props: {
    selectedNoteLine: {
      type: Object,
      default: () => null
    },
    value: {
      type: Object,
      description: 'From UICustomUI: Bound value from detail view',
      required: true
    },
    isNew: {
      type: Boolean,
      description: 'Used to know if is new and we should allocate all remaining',
      required: false,
      default: false
    }
  },
  data () {
    return {
      panel: 0,
      loading: false,
      props: {},
      note: null,
      promo: null,
      status: null,
      description: null,
      kbdType: null,
      detachment: null,
      storeModule: 'noteLines',
      allocatedEuro: 0,
      statusOptions: [],
      detachmentsOptions: [],
      dynamicStoreModule: 'notesForCampaigns',
      allocatedPercentageLocal: 0
    }
  },
  computed: {
    selectedElementLabel () {
      return this.note ? this.note.name : '-'
    },

    /**
     * This field and allocatedEuro are reactive with each other.
     * If one changes, the other is recalculated according to currentNoteValue
     * @returns: (Number) Allocated percentage computed property
     */
    allocatedPercentage: {
      get () {
        const percentage = Number(((this.allocatedEuro / this.currentNoteValue) * 100).toFixed(2))
        return (isNaN(percentage) || percentage === Infinity || percentage === -Infinity) ? 0 : percentage
      },
      set (newValue) {
        this.allocatedEuro = Number(((newValue * this.currentNoteValue) / 100).toFixed(2))
      }

    },

    /**
     * @returns: (Number) Value of currently selected note, either in input field (PromoView) or current NoteDetail.
     * Used to calculate allocatedEuro and allocatedPercentage values
     */
    currentNoteValue () {
      return !this.note ? 0 : this.note.value
    },

    /**
     * Gets the remaining value for the selected note. (Without regard to the current NoteLine)
     * @returns {number}
     */
    currentNoteRemainingValue () {
      return !this.note ? 0 : this.note.calculated__remaining_value || this.note.calculated.remaining_value
    },

    /**
     * Gets the unallocated value for the selected note with reactivity: as the current NoteLine's allocated is modified.
     * Also depends on whether the NoteLine is being created or modified
     * @returns {number}
     */
    currentNoteUnallocated () {
      return !this.isNew
        ? this.currentNoteRemainingValue - this.allocatedEuro + this.selectedNoteLine.allocated
        : this.currentNoteRemainingValue - this.allocatedEuro
    },

    defaultQueryParams () {
      return { 'campaign_id': [this.value.campaign] }
    }
  },
  watch: {
    note () {
      if (this.isNew) this.allocatedEuro = this.currentNoteRemainingValue
    },
    status () {
      if (this.status?.code_name === 'reclass_requested') this.$refs.form.resetValidation()
    }
  },
  async created () {
    try {
      this.loading = true
      this.fillFormWithSelectedElement()

      const detachments = await this.getPromoDetachment(this.promo.id)
      this.detachmentsOptions = this.sortArray(detachments)
      if (this.detachmentsOptions.length === 0) {
        const message = 'There are no SD&TTs in status \'Executed\' in this customer activity'
        await this.$store.dispatch('addNotification', createNotification(message, 'warning'))
      } else {
        this.detachment = this.detachmentsOptions[0]
      }
      const promiseOptions = await this.$store.dispatch(`${this.storeModule}/getNoteFieldChoices`)
      this.statusOptions = promiseOptions[0]
    } catch (error) {
      await this.$store.dispatch('addNotification', createNotification(error.message, 'error'))
      this.$emit('close', { reload: false })
    } finally {
      this.loading = false
    }
  },
  methods: {
    fillFormWithSelectedElement () {
      this.promo = this.selectedNoteLine && this.selectedNoteLine.promo_detachment
        ? this.selectedNoteLine.promo_detachment.promo : this.value

      if (this.isNew) return
      this.note = this.selectedNoteLine.note
      this.panel = null
      this.props = this.selectedNoteLine.props
      this.description = this.selectedNoteLine.description
      this.kbdType = this.selectedNoteLine.props.kbd_type
      this.detachment = this.selectedNoteLine.promo_detachment
      this.status = this.selectedNoteLine.status.id
      this.allocatedEuro = this.selectedNoteLine.allocated
    },

    /**
   * Fill the detachments options based on the view.
   * @param promo_id
   * @returns {Promise<void>}
   */
    async getPromoDetachment (promo_id) {
      return (await apiClient.get(`/promo-detachments/?limit=500&offset=0&promo=${promo_id}`)).data.results
    },

    /**
   * Concat the kbd type with the detachment, it's used for the drop down options.
   * @param item the detachment option, cant be null
   * @returns {string}
   */
    getFieldText (item) {
      const promoCost = item.value ? `${item.value} €` : `${item.props.value_percentage} %`
      const fieldTextFirstPart = `${item.id} - ${promoCost}`
      return item.materialized.kbd_type_label ? `${fieldTextFirstPart} - ${item.materialized.kbd_type_label} - ${item.topic_name}`
        : `${fieldTextFirstPart} - ${item.topic_name}`
    },

    /**
   * Sort the detachments options.
   * @param items
   */
    sortArray (items) {
      return items.sort((a, b) => {
        let detachmentNameA = this.getFieldText(a)
        let detachmentNameB = this.getFieldText(b)
        if (detachmentNameA < detachmentNameB) {
          return -1
        }
        if (detachmentNameA > detachmentNameB) {
          return 1
        }
        return 0
      })
    },

    /**
   * Saves or updates current NoteLine after validating form
   * Depending on selectedNoteLine prop value (whether we are creating or editing NoteLine)
   */
    async saveOrUpdateForm () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          let action
          let requestBody = {
            promo_detachment: this.detachment.id,
            props: { ...this.props, kbd_type: this.kbdType },
            status: this.status.id,
            description: this.description,
            allocated: this.allocatedEuro,
            fake: false,
            active: true,
            is_deleted: false
          }

          if (this.note?.id) {
            requestBody['note'] = this.note.id
          }

          if (this.isNew) {
            action = requestBody.note === undefined ? 'createNoteLineWithoutNote' : 'createItemWithBody'
          } else {
            [ action, requestBody ] = [ 'patchItemWithBody',
              { pk: this.selectedNoteLine.id, body: requestBody } ]
          }

          await this.$store.dispatch(`${this.storeModule}/${action}`, requestBody)
          this.$emit('close', { reload: true })
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      }
    },

    /**
   * Set the noteLine kbd type and the detachment id.
   * @param detachment
   */
    setKbdType (detachment) {
      this.detachment = detachment
      this.kbdType = this.detachment.materialized.kbd_base_type_label || ''
    },

    /**
   * Validation rule that ensures the allocated value for the note is not exceeded (use absolutes)
   * and that the input & the note value have the same sign.
   * @returns {boolean|*}
   */
    allocatedRule () {
      if (this.status?.code_name === 'reclass_requested') return true
      const unallocated = !this.isNew ? Math.abs(this.currentNoteRemainingValue) - Math.abs(this.allocatedEuro) + Math.abs(this.selectedNoteLine.allocated)
        : Math.abs(this.currentNoteRemainingValue) - Math.abs(this.allocatedEuro)
      console.log('re', this.currentNoteRemainingValue)
      const equal_sign = !(this.currentNoteRemainingValue * this.allocatedEuro < 0)
      return (equal_sign && unallocated >= 0) || this.$t('lines.allocatedExceeded')
    },

    addSelection (value) {
      if (value) {
        this.panel = null

        this.note = value[0]
        this.promo = this.value
      }
    }
  }
}
