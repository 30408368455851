import Vue from 'vue'
import Component from 'vue-class-component'

import { filtersMixin } from '@/lib/uncrudtable/mixins/filters'
import { validationMixin } from '@/apps/core/mixins/validations'
import { createWarningNotification } from '@/lib/unnotificationsqueue'
import DynamicSelectorTable from '@/apps/titan/components/tables/DynamicSelectorTable/DynamicSelectorTable.vue'

@Component({
  mixins: [
    filtersMixin,
    validationMixin
  ],
  components: {
    DynamicSelectorTable
  },
  props: {
    selectedNoteLine: {
      type: Object,
      default: () => null
    },
    value: {
      type: Object,
      description: 'From UICustomUI: Bound value from detail view',
      required: true
    },
    isNew: {
      type: Boolean,
      description: 'Used to know if is new and we should allocate all remaining',
      required: false,
      default: false
    }
  },
  watch: {
    selectedElementLabel () {
      if (this.isNew) this.allocatedEuro = this.currentNoteRemainingValue
    }
  }
})

class NoteLineFormForNote extends Vue {
  panel = 0
  loading = false

  note = null
  status = null
  description = null
  props = null
  kbdType = null
  allocatedEuro = 0
  detachment = null
  statusOptions = []
  storeModule = 'noteLines'
  dynamicStoreModule = 'promoDetachmentsForNotes'
  selectedPromoDetachment = null
  allocatedPercentageLocal = 0

  async created () {
    try {
      this.loading = true
      this.fillFormWithSelectedElement()
      const promiseOptions = await this.$store.dispatch(
        `${this.storeModule}/getNoteFieldChoices`)
      this.statusOptions = promiseOptions[0]
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }

  get selectedElementLabel () {
    const promoDetachment = !this.isNew ? this.selectedNoteLine.promo_detachment : this.detachment
    return promoDetachment ? `${promoDetachment.promo.campaign.description} - ${promoDetachment.topic_name}` : '-'
  }

  /**
   * This field and allocatedEuro are reactive with each other.
   * If one changes, the other is recalculated according to currentNoteValue
   * @returns: (Number) Allocated percentage computed property
   */
  get allocatedPercentage () {
    let percentage = Number(((this.allocatedEuro / this.currentNoteValue) * 100).toFixed(2))
    return (isNaN(percentage) || percentage === Infinity) ? '0' : percentage
  }

  set allocatedPercentage (value) {
    this.allocatedEuro = Number(((value * this.currentNoteValue) / 100).toFixed(2))
  }

  /**
   * @returns: (Number) Value of currently selected note, either in input field (PromoView) or current NoteDetail.
   * Used to calculate allocatedEuro and allocatedPercentage values
   */
  get currentNoteValue () {
    return !this.value.value ? 0 : this.value.value
  }

  /**
   * Gets the remaining value for the selected note. (Without regard to the current NoteLine)
   * @returns {number}
   */
  get currentNoteRemainingValue () {
    return !this.value.calculated__remaining_value ? 0 : this.value.calculated__remaining_value
  }

  /**
   * Gets the unallocated value for the selected note with reactivity: as the current NoteLine's allocated is modified.
   * Also depends on whether the NoteLine is being created or modified
   * @returns {number}
   */
  get currentNoteUnallocated () {
    return !this.isNew
      ? this.currentNoteRemainingValue - this.allocatedEuro + this.selectedNoteLine.allocated
      : this.currentNoteRemainingValue - this.allocatedEuro
  }

  get defaultQueryParams () {
    return { 'note_id': [this.value.id] }
  }

  fillFormWithSelectedElement () {
    if (this.isNew) return

    this.panel = null
    this.note = this.selectedNoteLine.note
    this.promo = this.selectedNoteLine.promo_detachment.promo
    this.props = this.selectedNoteLine.props
    this.kbdType = this.selectedNoteLine.props.kbd_type
    this.detachment = this.selectedNoteLine.promo_detachment
    this.status = this.selectedNoteLine.status.id
    this.allocatedEuro = this.selectedNoteLine.allocated
    this.description = this.selectedNoteLine.description
  }

  /**
   * Saves or updates current NoteLine after validating form
   * Depending on selectedNoteLine prop value (whether we are creating or editing NoteLine)
   */
  async saveOrUpdateForm () {
    if (!this.detachment) {
      // eslint-disable-next-line no-return-await
      return await this.$store.dispatch('addNotification',
        createWarningNotification(this.$t('detachments.selectDetachment')))
    }

    if (this.$refs.form.validate()) {
      try {
        this.loading = true
        let action
        let requestBody = {
          note: this.note.id,
          promo_detachment: this.detachment.id,
          props: { ...this.props, kbd_type: this.kbdType },
          status: this.status,
          description: this.description,
          allocated: this.allocatedEuro,
          active: true,
          fake: false,
          is_deleted: false
        }

        if (this.isNew) {
          action = 'createItemWithBody'
        } else {
          [ action, requestBody ] = [ 'putItemWithBody',
            { pk: this.selectedNoteLine.id, body: requestBody } ]
        }
        await this.$store.dispatch(`${this.storeModule}/${action}`, requestBody)
        this.$emit('close', { reload: true })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }

  /**
   * Validation rule that ensures the allocated value for the note is not exceeded (use absolutes)
   * and that the input & the note value have the same sign.
   * @returns {boolean|*}
   */
  allocatedRule () {
    let unallocated = !this.isNew ? Math.abs(this.currentNoteRemainingValue) - Math.abs(this.allocatedEuro) + Math.abs(this.selectedNoteLine.allocated)
      : Math.abs(this.currentNoteRemainingValue) - Math.abs(this.allocatedEuro)
    let equal_sign = !(this.currentNoteRemainingValue * this.allocatedEuro < 0)
    return (equal_sign && unallocated >= 0) || this.$t('lines.allocatedExceeded')
  }

  addSelection (value) {
    if (!value) return
    const detachment = value[0]

    this.note = this.value
    this.promo = detachment.promo
    this.kbdType = detachment.materialized.kbd_base_type_label || ''
    this.detachment = detachment
    this.panel = null
  }
}

export default NoteLineFormForNote
