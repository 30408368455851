import Vue from 'vue'
import Component from 'vue-class-component'
import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import { mapState } from 'vuex'

@Component({
  components: {
    CrudTable
  },
  props: {
    storeModule: {
      type: String,
      default: null
    },
    defaultQueryParameters: {
      type: Object,
      default: null
    },
    showSelectPanel: {
      type: Boolean,
      default: true
    },
    showActionsPanel: {
      type: Boolean,
      default: false
    },
    showSearchPanel: {
      type: Boolean,
      default: true
    },
    showAddItemsCustom: {
      type: Boolean,
      default: false
    },
    addItems: {
      type: Boolean,
      default: false
    },
    activeSingleSelect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig },
      loading: function (state) { return state[this.storeModule].loading }
    })
  }
})

class DynamicSelectorTable extends Vue {
  items = null

  get buttonsStyle () {
    return this.showActionsPanel ? 'margin: -11vh 1vw 0 0' : 'margin: 0vh 1vw 0 0'
  }

  filterOptions () {
    return this.defaultQueryParameters ? {
      filterFields: this.defaultQueryParameters
    } : {}
  }

  onSelectItem (values) {
    this.$emit('selection', values)
  }
}

export default DynamicSelectorTable
