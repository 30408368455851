import Vue from 'vue'
import { mapState } from 'vuex'
import { isUndefined, isNull } from 'lodash'
import Component from 'vue-class-component'

import CrudTable from '@/lib/uncrudtable/components/CrudTable'
import DialogConfirmation from '@/lib/uncrudtable/components/forms/DialogConfirmation.vue'
import NoteLineFormForPromo from '@/apps/dido/components/forms/NoteLineFormForPromo/NoteLineFormForPromo.vue'
import NoteLineFormForNote from '@/apps/dido/components/forms/NoteLineFormForNote/NoteLineFormForNote.vue'

@Component({
  props: {
    isPromoDetailView: {
      type: Boolean,
      description: 'From UICustomUI: Defines CustomView rendering component, to show note or promo in form',
      default: true
    },
    value: {
      type: Object,
      description: 'From UICustomUI: Bound value from detail view',
      required: true
    }
  },
  components: {
    CrudTable,
    DialogConfirmation,
    NoteLineFormForPromo,
    NoteLineFormForNote
  },
  computed: {
    ...mapState({
      crudTableConfig: function (state) { return state[this.storeModule].crudTableConfig }
    }),
    ...mapState('campaigns', ['currentItem'])
  }
})

class NoteLinesTable extends Vue {
  customOptions = { 'sortBy': ['id'], 'sortDesc': [true] }
  loadingAddItemsCustom = false
  isNewForm = null
  showForm = false
  showDialogConfirmation = false
  selectedNoteLine = null

  get parentKey () {
    return this.isPromoDetailView ? 'promo_detachment__promo' : 'note'
  }

  get canAddNoteLines () {
    const all__can_add_note_lines = this.currentItem.calculated__all__can_add_note_lines
    return isUndefined(all__can_add_note_lines) || isNull(all__can_add_note_lines)
      ? true : all__can_add_note_lines
  }

  get requiredId () {
    return 'calculated__promo_id_field' in this.value ? this.value['calculated__promo_id_field'] : this.value.id
  }

  get storeModule () {
    return this.$router.currentRoute.name === 'CampaignDetail' ? 'campaignNoteLines' : 'noteLines'
  }

  get headers () {
    return this.$store.getters[`${this.storeModule}/getHeaders`]
  }

  get loading () {
    return this.$store.getters[`${this.storeModule}/getLoading`]
  }

  get updatedValue () {
    let customValue = {}
    if ('calculated__promo_id_field' in this.value) {
      customValue = this.value.promo
    } else {
      customValue = this.value
    }
    return customValue
  }

  get confirmOptions () {
    return {
      titleDialog: this.$t('notes.deleteNote'),
      textDialog: this.$t('notes.deleteMessage'),
      confirmText: this.$t('dialogs.confirmOption'),
      confirmColor: 'red'
    }
  }

  /**
   * Computes item redirect link's path and label into table's first column slot
   * @returns { path, name }
   */
  get linkAttributes () {
    const linkAttributes = {}
    let value = this.crudTableConfig.headers?.length > 0 ? this.crudTableConfig.headers[0].value : ''
    linkAttributes['path'] = `item.${value}`
    linkAttributes['name'] = value
    return linkAttributes
  }

  /**
   * Opens form for note line addition/edition
   * @param item (Object): When editing, the selected note line
   */
  async showNoteLineForm (item) {
    try {
      this.selectedNoteLine = item || null
      this.isNewForm = this.selectedNoteLine === null
      this.showForm = true
    } catch (error) {
      console.error(error)
    } finally {
      this.loadingAddItemsCustom = false
    }
  }

  /**
   * Opens confirmation dialog and selects item to be deleted for subsequent call
   * @param item (Object): selected note line
   */
  beforeDeleteNote (item) {
    this.selectedNoteLine = item
    this.showDialogConfirmation = true
  }

  /**
   * Called after dialog message is confirmed.
   * Selected item's id is already in memory in this.selectedNoteLine
   */
  async onDeleteNote () {
    try {
      await this.$store.dispatch(`${this.storeModule}/deleteItemThrow`, this.selectedNoteLine.id)
      this.onReloadItemList()
    } catch (error) {
      console.error(error)
    } finally {
      this.selectedNoteLine = null
    }
  }

  /**
   * Called whenever the form dialog is closed. Clears selected item and refreshes note if in NoteDetail
   * @param reload (Boolean): if true, reloads table
   */
  async onCloseForm ({ reload = false }) {
    this.selectedNoteLine = null
    this.showForm = false
    if (reload) {
      this.onReloadItemList()
      if (!this.isPromoDetailView) {
        await this.$store.dispatch('notes/getNote', this.requiredId)
      }
    }
  }

  /**
   * Reloads list with full query (i.e. necessary promo or note id)
   * @returns {Promise<void>}
   */
  onReloadItemList () {
    let query = { ordering: '-id' }
    query[this.parentKey] = this.requiredId
    this.$store.dispatch(`${this.storeModule}/getItemList`, query)
  }
}

export default NoteLinesTable
